/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {





        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages



                // Navigation
                var navMenu     = $('.main__nav');
                var navOpen     = $('.nav__open');
                var navClose    = $('.nav__close');

                $('body').focusin(function(e) {
                    if (!$.contains($(navMenu)[0], document.activeElement)) {
                        navClose.trigger('click');
                    }
                });

                //keyboard navigation
                navMenu.on('keydown', function(event){
                    // keyCode -> http://www.javascripter.net/faq/keycodes.htm
                    // 27 = esc
                    if  (event.keyCode === 27) {
                        navClose.trigger('click');
                    }
                });

                // open navigation
                navOpen.click(
                    function(){
                        navOpen.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navClose.attr('aria-expanded', navClose.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navMenu.addClass('open');
                    }
                );

                // close navigation
                navClose.click(
                    function(){
                        navOpen.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navClose.attr('aria-expanded', navClose.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navMenu.removeClass('open');
                    }
                );

                // toggle sub-menu
                var menuItems = document.querySelectorAll('.main_menu li.menu-item-has-children');
                var menuItemOpenClose = function(event){
                    if ( this.parentNode.classList.contains('open') ) {
                        this.parentNode.classList.remove('open');
                        this.setAttribute('aria-expanded', "false");
                    } else {
                        menuItems.forEach( function(e) {
                            e.classList.remove('open');
                            e.setAttribute('aria-expanded', "true");
                        });
                        this.parentNode.classList.add('open');
                        this.setAttribute('aria-expanded', "true");
                    }
                    event.preventDefault();
                    //event.stopPropagation();
                    return false;
                }
                Array.prototype.forEach.call(menuItems, function(el, i){
                	el.querySelector('a').addEventListener("click", menuItemOpenClose, false);
                });





                // Header after scroll
                var headerScrollAnchor = $('#main').first().offset().top - 100;
                var header = $('#header');

                var headerFixed = function(){
                    var wst = $(window).scrollTop();
                    if( wst >= headerScrollAnchor ){
                        header.addClass('after-scroll');
                    } else {
                        header.removeClass('after-scroll');
                    }
                };
                headerFixed();

                $(window).on('scroll', function() {
                    headerFixed();
                });





                // AOS - animate on scroll
                // setting
                AOS.init({
                    offset: 100,
                    once: true,
                });





                // Magnific Popup
                // Image
                var magPopImg = $('.magnificpopup');
                magPopImg.magnificPopup({
                    type: 'image',
                    image: {
                        titleSrc:
                        function(item) {
                            return item.el.find('img').attr('alt');
                        }
                    }
                });

                // Video
                var magPopVideo = $('.magnificpopup--video');
                magPopVideo.magnificPopup({
                    type: 'iframe',
                    iframe: {
                        markup:
                            '<div class="mfp-iframe-scaler">'+
                                '<div class="mfp-close"></div>'+
                                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                            '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
                        patterns: {
                            youtube: {
                                index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                                id: 'v=', // String that splits URL in a two parts, second part should be %id%
                                // Or null - full URL will be returned
                                // Or a function that should return %id%, for example:
                                // id: function(url) { return 'parsed id'; }

                                src: '//www.youtube.com/embed/%id%' // URL that will be set as a source for iframe.
                            },
                        },
                    }
                });

                // Gallery
                var magPopGallery = $('.magnificpopup--gallery');
                magPopGallery.each(function() { // the containers for all your galleries

                    $(this).find('a').magnificPopup({
                        //delegate: 'a', // the selector for gallery item
                        type: 'image',
                        gallery: {
                            enabled: true
                        },
                        image: {
                            titleSrc:
                                function(item) {
                                    return item.el.find('img').attr('alt');
                                },
                        },
                    });

                });





            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },










        // Tax Sistemazione
        'tax_sistemazione': {
            init: function() {
                // JavaScript to be fired on the home page


                var slickOptions = {
                    arrows: true,
                    autoplay: false,
                    autoplaySpeed: 4000,
                    infinite: false,
                    pauseOnHover: true,
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1281,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 1081,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 861,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                    ],
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                };

                // var sliderFacet = $('.facetwp-template .items');// all
                var sliderFacet = $('.tipologia:not(.tipologia--standard):not(.tipologia--default) .items');
                sliderFacet.slick(slickOptions);

                $(document).on('facetwp-loaded', function() {

                    var sliderFacet = $('.tipologia:not(.tipologia--standard):not(.tipologia--default) .items');
                    sliderFacet.slick(slickOptions);

                });





            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },










        // Single Soluzione
        'single_soluzione': {
            init: function() {
                // JavaScript to be fired on the home page



                var magPopVR360 = $('.magnificpopup--vr360');
                magPopVR360.magnificPopup({
                    type: 'iframe',
                    iframe: {
                        patterns: {
                            external: {
                                index: 'https://streetview.genial.it/', // Sostituisci con il dominio esterno
                                // index: '', // Lascia l'index vuoto per accettare qualsiasi dominio
                                id: function(url) {
                                    return url;
                                },
                                src: '%id%' // Magnific Popup sostituirà %id% con l'URL dall'href
                            }
                        }
                    },
                    callbacks: {
                        open: function() {
                            console.log('Popup aperto con URL esterno.');
                        }
                    }
                });



                var magPopMaps = $('.magnificpopup--maps');
                magPopMaps.magnificPopup({
                    type:'inline',
                    midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
                });



                var slickOptions = {
                    arrows: true,
                    autoplay: false,
                    autoplaySpeed: 2000,
                    infinite: false,
                    pauseOnHover: true,
                    dots: false,
                    // variableWidth: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1280,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        },
                    ],
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                };
                var sliderGallery = $('.soluzione--gallery .images');
                sliderGallery.slick(slickOptions);



                // Mappa OpenStreetMap Soluzioni + markers ( MarkerClusterer )
                var lat = 45.5646604;
                var lon = 10.7129991;
                var zoom = 15;


                //Google maps API initialisation
                var element = document.getElementById("mappaOSM");
                // console.log(element);
                if (element != null) {

                    var map = new google.maps.Map(element, {
                        center: new google.maps.LatLng(lat, lon),
                        zoom: 12,
                        mapTypeId: "OSM",
                        mapTypeControl: false,
                        streetViewControl: false
                    });

                    //Define OSM map type pointing at the OpenStreetMap tile server
                    map.mapTypes.set("OSM", new google.maps.ImageMapType({
                        getTileUrl: function (coord, zoom) {
                            // "Wrap" x (logitude) at 180th meridian properly
                            // NB: Don't touch coord.x because coord param is by reference, and changing its x property breakes something in Google's lib
                            var tilesPerGlobe = 1 << zoom;
                            var x = coord.x % tilesPerGlobe;
                            if (x < 0) {
                                x = tilesPerGlobe + x;
                            }
                            // Wrap y (latitude) in a like manner if you want to enable vertical infinite scroll

                            return "https://tile.openstreetmap.org/" + zoom + "/" + x + "/" + coord.y + ".png";
                        },
                        tileSize: new google.maps.Size(256, 256),
                        name: "OpenStreetMap",
                        maxZoom: 19
                    }));

                    // markers ( MarkerClusterer )
                    var markerBounds = new google.maps.LatLngBounds();
                    var Point;
                    var markers = [];
                    var mcOptions = {
                        gridSize: 20,
                        maxZoom: 20
                    };
                    var mc = new markerClusterer.MarkerClusterer(map, markers, mcOptions);

                    $('.marker').each(function () {
                        //counterM++;
                        var latP = $(this).data("lat");
                        var lonP = $(this).data("lon");
                        //var nome = $(this).data("nome");
                        var src = $(this).data("src");
                        Point = new google.maps.LatLng(latP, lonP);


                        if (src != undefined) {
                            var marker = new google.maps.Marker({
                                map: map,
                                position: Point,
                                // icon: '<%= image_path("marker-blue.png") %>'
                            });
                            google.maps.event.addListener(marker, 'click', function () {
                                open_virtual(src)
                            });
                        } else {
                            var marker = new google.maps.Marker({
                                map: map,
                                position: Point
                            });
                        }

                        markers.push(marker);
                        // Extend markerBounds with each random point.
                        markerBounds.extend(Point);
                        map.fitBounds(markerBounds);
                        mc.addMarker(marker);
                    });
                }





            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },










        // Home
        'page_template_homepage': {
            init: function() {
                // JavaScript to be fired on the home page


                var slickOptions = {
                    arrows: true,
                    autoplay: false,
                    autoplaySpeed: 4000,
                    infinite: false,
                    pauseOnHover: true,
                    dots: false,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1401,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 1081,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 641,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                    ],
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                };

                var sliderServizi = $('.homepage--servizi .items');
                sliderServizi.slick(slickOptions);





            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },










        // Home
        'page_template_vacanze': {
            init: function() {
                // JavaScript to be fired on the home page


                var slickOptions = {
                    arrows: true,
                    autoplay: false,
                    autoplaySpeed: 4000,
                    infinite: false,
                    pauseOnHover: true,
                    dots: false,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1401,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 1081,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 641,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                    ],
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                };

                var sliderServizi = $('.vacanze--servizi .items');
                sliderServizi.slick(slickOptions);





            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },










        // Noi
        'page_template_noi': {
            init: function() {
                // JavaScript to be fired on the home page


                var slickOptions = {
                    arrows: true,
                    autoplay: false,
                    autoplaySpeed: 4000,
                    infinite: false,
                    pauseOnHover: true,
                    dots: false,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1281,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 1081,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 861,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 641,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                    ],
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                };

                var sliderTimeline = $('.noi--timeline .items');
                sliderTimeline.slick(slickOptions);





            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },










        // Contatti
        'page_template_contatti': {
            init: function() {
                // JavaScript to be fired on the home page

                // Mappa OpenStreetMap
                var lat = 45.5646604;
                var lon = 10.7129991;
                var zoom = 17;


                //Google maps API initialisation
                var element = document.getElementById("maps");
                // console.log(element);
                if (element != null) {

                    var map = new google.maps.Map(element, {
                        center: new google.maps.LatLng(lat, lon),
                        zoom: zoom,
                        mapTypeId: "OSM",
                        mapTypeControl: false,
                        streetViewControl: false
                    });

                    //Define OSM map type pointing at the OpenStreetMap tile server
                    map.mapTypes.set("OSM", new google.maps.ImageMapType({
                        getTileUrl: function (coord, zoom) {
                            // "Wrap" x (logitude) at 180th meridian properly
                            // NB: Don't touch coord.x because coord param is by reference, and changing its x property breakes something in Google's lib
                            var tilesPerGlobe = 1 << zoom;
                            var x = coord.x % tilesPerGlobe;
                            if (x < 0) {
                                x = tilesPerGlobe + x;
                            }
                            // Wrap y (latitude) in a like manner if you want to enable vertical infinite scroll

                            return "https://tile.openstreetmap.org/" + zoom + "/" + x + "/" + coord.y + ".png";
                        },
                        tileSize: new google.maps.Size(256, 256),
                        name: "OpenStreetMap",
                        maxZoom: 19
                    }));

                }



            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },










        // Contatti
        'page_template_mappa': {
            init: function() {
                // JavaScript to be fired on the home page

                // Mappa OpenStreetMap
                var lat = 45.5646604;
                var lon = 10.7129991;
                var zoom = 17;


                //Google maps API initialisation
                var element = document.getElementById("maps");
                // console.log(element);
                if (element != null) {

                    var map = new google.maps.Map(element, {
                        center: new google.maps.LatLng(lat, lon),
                        zoom: zoom,
                        mapTypeId: "OSM",
                        mapTypeControl: false,
                        streetViewControl: false
                    });

                    //Define OSM map type pointing at the OpenStreetMap tile server
                    map.mapTypes.set("OSM", new google.maps.ImageMapType({
                        getTileUrl: function (coord, zoom) {
                            // "Wrap" x (logitude) at 180th meridian properly
                            // NB: Don't touch coord.x because coord param is by reference, and changing its x property breakes something in Google's lib
                            var tilesPerGlobe = 1 << zoom;
                            var x = coord.x % tilesPerGlobe;
                            if (x < 0) {
                                x = tilesPerGlobe + x;
                            }
                            // Wrap y (latitude) in a like manner if you want to enable vertical infinite scroll

                            return "https://tile.openstreetmap.org/" + zoom + "/" + x + "/" + coord.y + ".png";
                        },
                        tileSize: new google.maps.Size(256, 256),
                        name: "OpenStreetMap",
                        maxZoom: 19
                    }));

                }



            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },










    };// Sage

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };// UTIL

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
